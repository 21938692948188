import CryptoJS from "crypto-js";
import { KeyApiAdapter } from "../adapters/keyApiAdapter";


export class CryptoService {

    constructor() {
        this.keyApiAdapter = new KeyApiAdapter();
    }
 
    generateSecretLink(password, key, keyId) {    
        var passwordWords = CryptoJS.enc.Utf8.parse(password)
        var encryptedWords = CryptoJS.AES.encrypt(passwordWords, key);
        var encryptedString = encryptedWords.toString();
        console.log("Encrypted: " + encryptedString);
    
        var hmac = CryptoJS.HmacSHA256(keyId, key).toString();
        console.log("HMAC: " + hmac)
    
        var path = keyId + ":" + hmac + ":" + encryptedString;
        var secretPath = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(path));
        console.log("Secret Path: " + secretPath);
        console.log("REACT_APP_FRONTEND_BASE_URL: " + process.env.REACT_APP_FRONTEND_BASE_URL)
    
        return process.env.REACT_APP_FRONTEND_BASE_URL + '/secret/#/' + secretPath;
    }

    decryptSecretPath(secretPath) {
        var plainTextSecretPath = CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(secretPath));
        var tokens = plainTextSecretPath.split(":");
    
        var keyId = tokens[0];
        var hmac = tokens[1];
        var encryptedString = tokens[2];

        return this.keyApiAdapter.getKey(keyId, hmac).then(key => {
            var decryptedWords = CryptoJS.AES.decrypt(encryptedString, key.value);
            var decryptedString = CryptoJS.enc.Utf8.stringify(decryptedWords);

            if (decryptedString === "") {
                throw new Error("Decryption failure");
            }
        
            console.log("Decrypted: " + decryptedString);
        
            return decryptedString;
        });
    }
}
