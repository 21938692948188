import * as React from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Component } from "react";

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];

class HowToUsePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
        activeStep: 0
    };
  }

  render() {
    return (
      <Grid container spacing={10}>
        <Grid item xs={0} sm={3}></Grid>
        <Grid item xs={12} sm={6}>
          <Box m={4}>
          <Stepper activeStep={this.state.activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {this.state.activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={this.handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>Step {this.state.activeStep + 1}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={this.state.activeStep === 0}
              onClick={this.handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={this.handleNext}>
              {this.state.activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </React.Fragment>
      )}
          </Box>
        </Grid>
        <Grid item xs={0} sm={3}></Grid>
      </Grid>
    );
  }

  handleNext = () => {
    this.setState({
        activeStep: this.state.activeStep + 1
    });
  };

  handleBack = () => {
    this.setState({
        activeStep: this.state.activeStep + 1
    });
  };

  handleReset = () => {
    this.setState({
        activeStep: 0
    });
  };
}

export default HowToUsePage;
