import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import CopyIcon from "@mui/icons-material/ContentCopy";
import { KeyApiAdapter } from "../adapters/keyApiAdapter";
import { Component } from "react";
import { CryptoService } from "../services/cryptoService";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";


class EncryptPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordInputValue: "",
      linkInputValue: "",
      showPassword: false,
      linkGenerationInProgress: false,
      showLinkInput: false,
      passwordInputValidationErrorMessage: ""
    };
    this.keyApiAdapter = new KeyApiAdapter();
    this.cryptoService = new CryptoService();
  }

  render() {
    return (
      <Grid container spacing={10}>
        <Grid item xs={0} sm={3}></Grid>
        <Grid item xs={12} sm={6}>
          <Box m={4}>
            <TextField
              error={this.state.passwordInputValidationErrorMessage !== ""}
              type={this.state.showPassword ? 'text' : 'password'}
              value={this.state.passwordInputValue}
              onChange={(evt) => this.updatePasswordInputValue(evt)}
              helperText={this.state.passwordInputValidationErrorMessage}
              fullWidth
              id="outlined-multiline-static"
              label="Password"
              rows={4}
              variant="outlined"
              InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={this.switchPasswordVisibility} onMouseDown={this.switchPasswordVisibility}>
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                  ),
              }}
            />
          </Box>
          <Box m={4} textAlign='right'>
            <LoadingButton
              onClick={this.handleCreateLinkButtonClick}
              endIcon={<SendIcon />}
              loading={this.state.linkGenerationInProgress}
              loadingPosition="end"
              variant="contained">
              Generate Link
            </LoadingButton>
          </Box>

          {this.state.showLinkInput ? 
          <Box m={4}>
            <TextField
              value={this.state.linkInputValue}
              fullWidth
              id="link"
              label="Link"
              InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={this.copyLinkToClipboard}>
                        <CopyIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
              }}
            />
          </Box>
          : null }
        </Grid>
        <Grid item xs={0} sm={3}></Grid>
      </Grid>
    );
  }

  updatePasswordInputValue(evt) {
    this.setState({
      passwordInputValue: evt.target.value,
      passwordInputValidationErrorMessage: ""
    });
  }

  handleCreateLinkButtonClick = () => {
    if (this.state.passwordInputValue === "") {
        this.setState({
            passwordInputValidationErrorMessage: "Password shouldn't be empty"
        })
        return;
    }

    console.log("Password: " + this.state.passwordInputValue);
    this.setState({
        linkGenerationInProgress: true,
        showLinkInput: false
    });

    this.keyApiAdapter.createKey().then((key) => {
      var secretLink = this.cryptoService.generateSecretLink(
        this.state.passwordInputValue,
        key.value,
        key.id
      );
      console.log("Secret Link: " + secretLink);
      this.setState({
        linkInputValue: secretLink,
        linkGenerationInProgress: false,
        showLinkInput: true
      });
    });
  };

  switchPasswordVisibility = () => {
    console.log("Visibility: " + !this.state.showPassword);
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  copyLinkToClipboard = (evt) => {
    navigator.clipboard.writeText(this.state.linkInputValue);

    // If command + click is pressed, open generated link in new tab. For testing only
    if (evt.metaKey) {
        window.open(this.state.linkInputValue, "_blank")
    }
  };
}

export default EncryptPage;
