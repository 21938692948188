import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { KeyApiAdapter } from "../adapters/keyApiAdapter";
import { Component } from "react";
import { CryptoService } from "../services/cryptoService";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CopyIcon from "@mui/icons-material/ContentCopy";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

class DecryptPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordInputValue: "",
      linkInputValue: "",
      showPassword: false,
      showErrorAlert: false,
      alertErrorMessage: "",
      showPasswordInput: false,
    };
    this.keyApiAdapter = new KeyApiAdapter();
    this.cryptoService = new CryptoService();
  }

  render() {
    return (
      <Grid container spacing={10}>
        <Grid item xs={0} sm={3}></Grid>
        <Grid item xs={12} sm={6}>
          <Box m={4}>
            {this.state.showPasswordInput ? (
              <TextField
                value={this.state.passwordInputValue}
                onChange={(evt) => this.updatePasswordInputValue(evt)}
                fullWidth
                id="outlined-multiline-static"
                label="Password"
                rows={4}
                variant="outlined"
                type={this.state.showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <IconButton
                          onClick={this.switchPasswordVisibility}
                          onMouseDown={this.switchPasswordVisibility}
                        >
                          {this.state.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                      <InputAdornment position="end">
                        <IconButton onClick={this.copyLinkToClipboard}>
                          <CopyIcon />
                        </IconButton>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            ) : null}
            {this.state.showErrorAlert ? (
              <Alert severity="error" variant="outlined">
                <AlertTitle>Error</AlertTitle>
                {this.state.alertErrorMessage}
              </Alert>
            ) : null}
          </Box>
        </Grid>
        <Grid item xs={0} sm={3}></Grid>
      </Grid>
    );
  }

  componentDidMount() {
    var url = window.location.href;
    var prefix = "/secret/#/";
    var index = url.indexOf(prefix);
    // handle index == -1
    var secretPath = url.slice(index + prefix.length);
    console.log("Secret Path: " + secretPath);

    try {
      this.cryptoService
      .decryptSecretPath(secretPath)
      .then((password) => {
        console.log("Password: " + password);
        this.setState({
          showPasswordInput: true,
          passwordInputValue: password,
        });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            if (error.response.data.code === "KEY_NOT_FOUND") {
              this.setState({
                showErrorAlert: true,
                alertErrorMessage: "Password has been already read once!",
              });
              return;
            } else if (error.response.data.code === "HMAC_VALIDATION_FAILURE") {
              this.setState({
                showErrorAlert: true,
                alertErrorMessage: "Something is wrong with the link! Please check it",
              });
              return;
            }
          }
        } else {
          this.setState({
            showErrorAlert: true,
            alertErrorMessage: "Something is wrong with the link! Please check it",
          });
          return
        }
        this.setState({
          showErrorAlert: true,
          alertErrorMessage: "Unexpected error occurred! Please try again",
        });
      });
    } catch(e) {
      this.setState({
        showErrorAlert: true,
        alertErrorMessage: "Something is wrong with the link! Please check it",
      });
    }
  }

  handleCreateLinkButtonClick = () => {
    console.log("Password: " + this.state.passwordInputValue);
    this.keyApiAdapter.createKey().then((key) => {
      var secretLink = this.cryptoService.generateSecretLink(
        this.state.passwordInputValue,
        key.value,
        key.id
      );
      console.log("Secret Link: " + secretLink);
      this.setState({
        linkInputValue: secretLink,
      });
    });
  };

  switchPasswordVisibility = () => {
    console.log("Visibility: " + !this.state.showPassword);
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  updatePasswordInputValue(evt) {
    this.setState({
      passwordInputValue: evt.target.value,
    });
  }

  copyLinkToClipboard = () => {
    navigator.clipboard.writeText(this.state.passwordInputValue);
  };
}

export default DecryptPage;
