import "./App.css";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Component } from "react";
import EncryptPage from "./components/EncryptPage";
import DecryptPage from "./components/DecryptPage";
import HowToUsePage from "./components/HowToUsePage";
import PrivacyPolicyPage from "./components/PrivacyPolicyPage";

class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <NavBar />
          <Routes>
            <Route path="/" element={<EncryptPage />} />
            <Route path="/secret/" element={<DecryptPage />} />
            <Route path="/how-to-use" element={<HowToUsePage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
