import React from 'react'
import Box from "@mui/material/Box";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'
import KeyIcon from "@mui/icons-material/VpnKey";
import Grid from "@mui/material/Grid";






const NavBar = () => {
    return(
        <div>
        <AppBar position="static">
            <Toolbar>
                <Box sx={{ flexGrow: 1 }}>
                <Grid container direction="row" alignItems="center">
                    <KeyIcon sx={{ mr: 2}}/>
                    <Typography variant="title" color="inherit" component={Link} to="/" style={{ textDecoration: 'none' }}>
                        Password Pass
                    </Typography>
                </Grid>



                </Box>
                <Button color="inherit" component={Link} to="/how-to-use">How to Use</Button>
            </Toolbar>
        </AppBar>
        </div>
    )
}

export default NavBar;
